import React from 'react'
import styled from 'styled-components'
import styles from './_styles'
import { Container, Row, Col } from 'styled-bootstrap-grid' 

// layouts
import RootLayout from '@Layouts/root'

// sections
import Header from '@Sections/simple-header'

// assets
import arnostekHead from '@Images/arnost-head.png'

const Page = styled.section`${styles}`
const Homeopatie = () => {
  return (
    <RootLayout headerType="disable-default-header">
      <Page>
        <Header />
        <Container style={{ minHeight: 'calc(100vh - 178px)' }}>
          <Row style={{ padding: '40px 0' }}>
            <Col>
              <h1>Homeopatie</h1>
            </Col>
            <Col md="6">
              <img src={arnostekHead} alt="Arnoštek" style={{ maxWidth: '420px', width: '100%' }} />
            </Col>
            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
              <h2>... ještě na tom makáme!</h2>
            </Col>
          </Row>
        </Container>
      </Page>
    </RootLayout>
  )
}

export default Homeopatie